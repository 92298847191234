<template>
  <div>
    <v-card>
      <v-card outlined>
        <v-card-title class="title pb-0">
          <v-flex xs3 sm4>
            <v-select
              :items="types"
              class="pa-0 mt-2"
              label="Invoice"
              v-model="invoiceType"
              outlined
              dense
            />
          </v-flex>
        </v-card-title>
      </v-card>

      <br />
      <v-data-table
        :headers="headers"
        :items="form.items.data"
        :options.sync="pagination"
        :footer-props="footerProps"
        :server-items-length="form.items.meta.total"
        footer-props.items-per-page-options="rowsPerPageItems"
        :loading="form.loading"
        class="elevation-0"
        hide-default-footer
      >
        <template v-slot:item="{ index, item }">
          <tr @dblclick="openKGAlert(item)">
            <td
              :class="{ sent_to_kg: item.sent_to_kg === 1 && $auth.isSuper() }"
            >
              {{ index + 1 }}
            </td>
            <td class="text-xs-left heads_td">
              <div
                class="mt-2 mb-1 "
                v-for="(head, key) in item.payment_heads"
                :key="key"
              >
                <p class="mt-0 mb-0" style="font-size: 15px;font-weight: bold">
                  {{ head.title }}
                  <small style="font-weight: normal;color: #888;"
                    >({{ head.amount_no_discount.numberFormat() }})</small
                  >
                </p>
              </div>
              <div v-if="item.total_discount > 0" class="mt-2 mb-1 ">
                <p class="mt-0 mb-0" style="font-size: 15px;font-weight: bold">
                  Discount
                  <small style="font-weight: normal;color: #888;"
                    >({{ item.total_discount.numberFormat() }})</small
                  >
                </p>
              </div>
            </td>
            <td class="text-xs-left">
              <br />
              <strong>{{ item.payment_date }}</strong>
              <p
                :class="
                  `${item.status !== 1 ? item.action_type : ''}--text mb-0 mt-0`
                "
              >
                {{ item.human }}
              </p>
            </td>
            <td class="text-xs-center">
              <strong>{{
                totalAmountOnHeads(item.payment_heads).currency()
              }}</strong>
            </td>
            <td class="text-xs-left">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="!item.invoices.length"
                    small
                    outlined
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon left small>cloud_download</v-icon>&nbsp; Invoice
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(invoice, index) in item.invoices"
                    :key="index"
                    @click="downloadDocument(invoice)"
                  >
                    <v-list-item-title
                      >Download Invoice ({{ index + 1 }})</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-btn
                class="mt-1"
                :disabled="!(item.status === 1)"
                @click="downloadReceipt(item)"
                small
                outlined
                color="success"
                dark
              >
                <v-icon left small>cloud_download</v-icon>&nbsp; Download
                Receipt
              </v-btn>
            </td>
            <td class="text-xs-left">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="!item.vouchers.length"
                    small
                    outlined
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon left small>cloud_download</v-icon>&nbsp; Voucher
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(voucher, index) in item.vouchers"
                    :key="index"
                    @click="downloadDocument(voucher)"
                  >
                    <v-list-item-title
                      >Download Voucher ({{ index + 1 }})</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
            <td class="text-left">
              <v-chip v-if="item.status === 1" label color="success">
                <v-icon class="mr-2">check_circle</v-icon>
                &nbsp;&nbsp;&nbsp;Complete
              </v-chip>
              <v-chip v-else-if="item.status === 3" label color="warning">
                <v-icon class="mr-2">error</v-icon> &nbsp;&nbsp;&nbsp;Under
                Review
              </v-chip>
              <v-chip label color="error" v-else>
                <v-icon class="mr-2">check_circle</v-icon>
                &nbsp;&nbsp;&nbsp;Pending
              </v-chip>
            </td>

            <td class="text-right">
              <edit-button
                v-if="$auth.getRole() === 'super'"
                edit-text="Select File"
                @agree="
                  $refs.image.click(),
                    (documentType = 'invoice'),
                    (invoice_id = item.id)
                "
                :disabled="item.invoices.length > 0"
                permission="school-create"
                label="Upload Invoice"
                icon="article"
              >
              </edit-button>
              <edit-button
                v-else-if="item.status !== 1"
                edit-text="Select File"
                @agree="
                  $refs.image.click(),
                    (documentType = 'voucher'),
                    (invoice_id = item.id)
                "
                :disabled="item.status === 3"
                permission="staff-create"
                :label="
                  item.status === 0 ? 'Upload Voucher' : 'Replace Voucher'
                "
                icon="article"
              >
              </edit-button>

              <br />

              <div class="mt-1" v-if="$auth.getRole() === 'super'">
                <edit-button
                  @agree="approvePayment(item)"
                  color="success"
                  edit-text="Approve Payment"
                  :disabled="item.status !== 3"
                  permission="staff-create"
                  :label="'Approve Payment'"
                  icon="article"
                >
                </edit-button>
              </div>

              <div class="mt-1">
                <edit-button
                  @agree="item.status = 1"
                  v-if="$auth.getRole() === 'super'"
                  color="error"
                  edit-text="Extend Date"
                  :disabled="item.status === 1"
                  permission="staff-create"
                  :label="'Extend Date'"
                  icon="article"
                >
                </edit-button>
              </div>

              <input
                type="file"
                style="display: none"
                ref="image"
                multiple
                accept="image/*"
                @change="onFilePicked"
              />
            </td>
          </tr>
        </template>
      </v-data-table>
      <br />
      <br />
    </v-card>

    <v-dialog v-model="showKGAlert" persistent max-width="400px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Add/Update</span>
        </v-card-title>
        <v-card-text class="pb-1 pt-4">
          <v-flex xs12>
            <div
              class="plan_info"
              v-for="(item, key) in kgPaymentList.payment_heads"
              :key="key"
            >
              <div><v-checkbox v-model="item.send_to_kg"></v-checkbox></div>
              <div class="pi_content plan_info_title">{{ item.title }}</div>
              <div class="pi_content plan_info_amount">
                {{ item.amount.currency() }}
              </div>
            </div>
          </v-flex>
          <hr />
          <v-textarea
            rows="4"
            outlined
            dense
            v-model="kg_remarks"
            label="Remarks"
            hint="Add remarks for the payment"
          />
          <p class="mt-2">
            Total being sent to KG
            <strong v-if="totalSentToKg" style="color:red;">
              <big>{{ totalSentToKg.numberFormat() }}</big></strong
            >
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            text
            @click="(showKGAlert = false), (kgPaymentList = {})"
            >Close</v-btn
          >
          <v-btn
            :loading="sending_to_kg"
            :disabled="totalSentToKg < 1"
            color="success"
            text
            @click="sendToKG"
          >
            Send To KG</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],

  props: ["schoolId"],
  name: "Payments",
  data: () => {
    return {
      types: [
        {
          value: "pending",
          text: "Pending",
        },
        {
          value: "complete",
          text: "Paid",
        },
        {
          value: "partial",
          text: "Partial Paid",
        },
      ],
      invoiceType: "pending",
      form: new Form({}, "/api/payment-alert"),
      documentType: "",
      invoice_id: "",
      headers: [
        { text: "#", align: "left", value: "id", width: 20, sortable: false },
        {
          text: "Head",
          align: "left",
          value: "name",
          sortable: false,
          width: 250,
        },
        {
          text: "Payment Date",
          align: "left",
          value: "name",
          sortable: false,
          width: 150,
        },
        {
          text: "Amount",
          align: "left",
          value: "name",
          sortable: false,
          width: 150,
        },
        {
          text: "Invoice / Receipt",
          align: "left",
          value: "name",
          sortable: false,
          width: 150,
        },
        {
          text: "Voucher",
          align: "left",
          value: "voucher",
          sortable: false,
          width: 150,
        },
        {
          text: "Payment Status",
          align: "left",
          value: "status",
          sortable: false,
          width: 150,
        },
        { text: "Action", align: "right", sortable: false },
      ],
      totalAmount: 0,
      showKGAlert: false,
      kgPaymentList: {},
      sendKgAmount: 0,
      kg_remarks: "",
      sending_to_kg: false,
    };
  },
  computed: {
    totalSentToKg() {
      let output = "";
      let totalAmount = 0;

      if (Object.keys(this.kgPaymentList).length > 0) {
        this.kgPaymentList.payment_heads.map((item) => {
          if (item.send_to_kg === true) {
            totalAmount += item.amount_no_vat;
          }
        });
        totalAmount = Math.round(totalAmount);
        return Math.round(totalAmount * 0.2);
      }
      return output;
    },
  },
  mounted() {
    this.fetchPaymentList();
  },
  methods: {
    openKGAlert(item) {
      if (item.sent_to_kg === 0 && this.$auth.isSuper() && item.status === 1) {
        this.$auth.isSuper();
        this.kgPaymentList = item;
        this.showKGAlert = true;
      }
    },
    sendToKG() {
      this.sending_to_kg = true;
      this.$rest
        .post("/api/payment/send-to-kg", {
          items: this.kgPaymentList,
          send_amount: this.totalSentToKg,
          company_id: this.schoolId,
          remarks: this.kg_remarks,
        })
        .then((res) => {
          this.$events.fire("notification", {
            message: "Amount was sent to KG successfully",
            status: "success",
          });
          this.showKGAlert = false;
          this.kgPaymentList = {};
          this.fetchPaymentList();
        })
        .catch((err) => {
          this.$events.fire("notification", {
            message: "Failed to send to KG",
            status: "error",
          });
        })
        .finally(() => {
          this.sending_to_kg = false;
        });
    },
    fetchPaymentList() {
      let endpoint = "/api/payment-alert";
      if (this.schoolId && this.$auth.getRole() === "super") {
        endpoint += "?school_id=" + this.schoolId;
      }
      this.form.get(endpoint).then(({ data }) => {
        this.totalAmount = 0;
        data.data.map((item) => {
          item.payment_heads.map((head) => {
            this.totalAmount += head.amount;
          });
        });
      });
    },
    downloadReceipt(item) {
      let url = null;
      if (
        location.host === "localhost:8080" ||
        location.host === "eacademy.sahakaryatech.com"
      ) {
        url = "https://reporting.sahakaryatech.com/print/shk/eacademy/receipt";
      } else {
        url = "https://reporting.eacademynepal.com/print/shk/eacademy/receipt";
      }

      if (url) {
        window.open(
          url + "/" + this.$auth.company("id") + ":" + item.id,
          "Electronic Payment Receipt",
          "toolbar=0,scrollbars=1,location=0,statusbar=0,status=0,menubar=1,resizable=0,width=1000,height=900,titlebar=0"
        );
      }
    },
    totalAmountOnHeads(heads) {
      let value = 0;
      heads.map((item) => {
        value += item.amount;
      });
      return value;
    },
    onFilePicked(e) {
      // console.log("uploading voucher")
      const files = e.target.files;
      const formData = new FormData();
      formData.append("document_type", this.documentType);
      // formData.append('documents[]', file);
      formData.append("invoice_id", this.invoice_id);
      files.forEach((file) => {
        formData.append("documents[]", file);
      });

      this.$rest
        .post("/api/payment/document", formData)
        .then(({ data }) => {
          this.$events.fire("notification", { message: data.message });
          this.fetchPaymentList();
        })
        .catch((err) => {
          this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });
        });
    },
    approvePayment(item) {
      //   console.log(item);
      this.$rest
        .put("/api/verify-payment/" + item.id, {
          ...item,
          company_id: this.schoolId,
          status: 1,
        })
        .then((res) => {
          this.$events.fire("notification", {
            message: "Approved successfully",
            status: "success",
          });
          this.fetchPaymentList();
        })
        .catch((err) => {
          this.$events.fire("notification", {
            message: "Failed",
            status: "error",
          });
        });
    },
    downloadDocument(doc) {
      window.open(doc, "_blank");
    },
  },
};
</script>

<style lang="scss">
.v-alert__border--bottom {
  border-bottom: 2px solid indianred;
}

.heads_td {
  div:not(:last-child) {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.plan_info {
  display: flex;
  margin: 20px 0;
  padding-bottom: 15px;
  border-bottom: 1px solid #cfcfcf;
  &:last-child {
    border-bottom: none;
  }
  .pi_content {
    flex: 1;
    font-size: 17px;
    font-weight: 500;
  }
  .plan_info_amount {
    text-align: right;
    color: #666;
    font-size: 18px;
    font-weight: bold;
  }
  .plan_info_title {
    color: #939a9f;
  }
}
.v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.v-input__slot {
  margin-bottom: 0 !important;
}
.v-messages {
  min-height: 0 !important;
}
.sent_to_kg {
  position: relative;
  &:after {
    content: "";
    background: #4caf50;
    position: absolute;
    bottom: 4%;
    left: 0;
    height: 92%;
    width: 5px;
  }
}
</style>
